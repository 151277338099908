import React, { lazy, Suspense, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import './Home.css';
import Header from "../../component/header/Header";
import MiniBannerTop from "../../component/minibanner/MiniBannerTop";
import MiniBannerBottom from "../../component/minibanner/MiniBannerBottom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from "../../component/carousel/Carousel";
import Gallery from "../../component/gallery/Gallery";
import OurStory from "../../component/ourStory/OurStory";
import Location from "../../component/location/Location";
import OurBlog from "../../component/ourBlog/OurBlog";
import NewsLetter from "../../component/newsLetter/NewsLetter";
import Reservation from "../../component/reservation/Reservation";
import Testimonials from "../../component/testimonials/Testimonials";
import SplitView from "../../component/SplitView/SplitView";
import Footer from "../../component/footer/Footer";
import { useLocation } from "react-router-dom";
import { storedEncryptedKey } from "./constHome";
import Alert from '@mui/material/Alert';
import { originalKey } from "./constHome";
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import axios from 'axios';
function Home({ home, getLocation, headermenu, businessHours, carryoutHours, alldata, banner, AllMainLocation, themeData, footerData, allLocation, allLocationNavigtion, getreslistData, mainLocation , loading, substoredEncryptedKey, storedEncryptedKey, mainTheme,res_id}) {
    const resid = res_id;
    localStorage.setItem('resid', resid);
    const { height, width } = useWindowDimensions();
    const windowWidth = width;
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

    // announcement
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        const announcementPopupTimeout = setTimeout(() => {
            setOpen(true);
        }, 2000);
        return () => {
            clearTimeout(announcementPopupTimeout);
        };
    }, []);

    // res cookies
    const Res_Cookie_Policy = localStorage.getItem('Res_Cookie_Policy');
    const [showcookies, setShowcookies] = React.useState(false);           
        const handlecookiesClose = () => {
            setShowcookies(false);
        }

        const handlecookiesGotit = () => {
            localStorage.setItem('Res_Cookie_Policy', 'done');
            setShowcookies(false);
        }

        useEffect(() => {
            if (Res_Cookie_Policy != 'done') {
                const cookiesPopupTimeout = setTimeout(() => {
                    setShowcookies(true);
                }, 1000);
                return () => {
                    clearTimeout(cookiesPopupTimeout);
                };
            }
        }, []);
    
    const [getresclose, setResClose] = React.useState([]);
    // res close modal
    useEffect(() => {
        const fetchcloseWebData = async () => {
          try {
            if(res_id != '' || res_id){
                // const getrescloseResponse = await axios.get(`https://www.dev.eatstations.com/api/res_close_data/5`);
                const getrescloseResponse = await axios.get(`https://www.eatstations.com/api/res_close_data/${res_id}`);
                setResClose(getrescloseResponse.data);
              }
            } catch (error) {
              console.error('Error fetching home data: ', error);
            }
          };
          fetchcloseWebData();
      }, []);
    const [Resclosemodal, setRescloseModal] = React.useState(false);
    const resclosehandleClose = () => {
        setRescloseModal(false);
    }
    useEffect(() => {
        const resclosePopupTimeout = setTimeout(() => {
            setRescloseModal(true);
        }, 2000);
        return () => {
            clearTimeout(resclosePopupTimeout);
        };
    }, []);

    let webLocationLength;
    home?.map((homeDate)=>{
        if(homeDate.section_id === 6){
            webLocationLength = homeDate.data.length;
        }
    })
    if (loading == false && originalKey === storedEncryptedKey) {
        return (
            <>
                <Box sx={{ backgroundColor: '#fff' }}>
                    <Suspense fallback={<div><div style={{ width: '100vw', height: '100vh', backgroundImage: `url(${process.env.PUBLIC_URL + '/loader_image.jpg'})`, backgroundSize: 'cover' }}></div></div>}>
                        <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
                        <div className={getLocation?.banner_image_video === 'image' ? mainTheme.layout == 1 || mainTheme.layout == 5 ? "banner_div1" : 'banner_div' : 'banner_video_div'}>
                            <Carousel type="BannerCarousel" banner={banner} getLocation={getLocation} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                        </div>
                        {home && home?.map((homeDate) => (
                            <React.Fragment key={homeDate.section_id}>
                                {homeDate.section_id === 1 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}
                                    >
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img
                                                className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,
                                                    width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        { homeDate.action == 'show' && (
                                            <Box sx={{osition:'relative',zIndex:13}}>
                                                <Box sx={{ paddingTop: `${homeDate.section_border_top_type == 2 || homeDate.section_border_top_type == 3 || homeDate.mini_banner_top !== '0' ? `50px` : `0`}` }}>
                                                    <Typography
                                                        sx={{ textAlign: 'center' }}
                                                        variant="h4"
                                                        dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                                                </Box>
                                            </Box>                                            
                                        )}
                                        <Gallery homeDate={homeDate} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner bottom is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,
                                                    width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData} />
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 2 && (
                                    <Box sx={{
                                        position: 'relative',
                                        borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                        borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                    }}>
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img
                                                className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <OurStory homeDate={homeDate} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner bottom is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData} />
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 3 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}>
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img
                                                className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <NewsLetter homeDate={homeDate} alldata={alldata} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} webLocationLength={webLocationLength} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner bottom is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 4 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}>
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img
                                                className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <Box sx={{ paddingTop: `${homeDate.section_border_top_type == 2 || homeDate.section_border_top_type == 3 || homeDate.mini_banner_top !== '0' ? `50px` : `0`}` }}>
                                            { homeDate.action == 'show' && (
                                                <Box sx={{osition:'relative',zIndex:13}}>
                                                    <Typography
                                                        className="OurBlogHeader"
                                                        sx={{
                                                            textAlign: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            paddingTop: `${homeDate.section_border_top_type == 2 || homeDate.section_border_top_type  == 3 ? `45px` : `0`}`
                                                        }}
                                                        variant="h4"
                                                        dangerouslySetInnerHTML={{ __html: homeDate.title }} />
                                                </Box>
                                            )}
                                            <OurBlog homeDate={homeDate} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        </Box>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner bottom is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 5 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}
                                    >
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img     className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <Testimonials homeDate={homeDate} getLocation={getLocation} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 6 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}
                                    >
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img  className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                loading="lazy"
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                            />
                                        )}
                                        {homeDate.data.length > 1 && (
                                            <Location homeDate={homeDate} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength} alldata={alldata}/>
                                        )}
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 7 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}>
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img  className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <Reservation homeDate={homeDate} themeData={themeData} alldata={alldata} getreslistData={getreslistData} getLocation={getLocation} mainLocation={mainLocation} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 8 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`
                                        }}>
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img  className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                   top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <Box sx={{position:'relative',textAlign:'center'}}>
                                            <Box id='Slide_Banner' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'100%'}}></Box>
                                            <Carousel type="SlideCarousel" homeDate={homeDate} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        </Box>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                                {homeDate.section_id === 9 && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            borderTop: `${homeDate.section_border_top_type == 1 ? `20px solid ${homeDate.section_border_top_color}` : `0px`}`,
                                            borderBottom: `${homeDate.section_border_bottom_type == 1 ? `20px solid ${homeDate.section_border_bottom_color}` : `0px`}`,
                                        }}
                                    >
                                        {homeDate.mini_banner_top !== '0' && homeDate.mini_banner_top !== '' && homeDate.minibanner_top !== null && (
                                            <MiniBannerTop homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                        {homeDate.section_border_top_image != '' && (
                                            <img  className="section_top_image"
                                                src={`${homeDate.section_border_top_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    top :  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,
                                                    width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(0deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        <SplitView homeDate={homeDate} themeData={themeData} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                                        {homeDate.section_border_bottom_image != '' && (
                                            <img  className="section_bottom_image"
                                                src={`${homeDate.section_border_bottom_image}`}
                                                alt='mini banner top is not found'
                                                style={{
                                                    position: 'absolute',
                                                    bottom:  windowWidth < 600 ? '-20px' : '-30px',
                                                    zIndex: 100,
                                                    width: '100%',
                                                    height: windowWidth < 600 ? '20px' : '30px',
                                                    transform: `rotate(180deg)`,
                                                }}
                                                loading="lazy"
                                            />
                                        )}
                                        {homeDate.mini_banner_bottom !== '0' && homeDate.mini_banner_bottom !== '' && homeDate.minibanner_bottom !== null && (
                                            <MiniBannerBottom homeDate={homeDate} allLocationNavigtion={allLocationNavigtion} mainTheme={mainTheme} themeData={themeData}/>
                                        )}
                                    </Box>
                                )}
                            </React.Fragment>
                        ))}
                        <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                    </Suspense>
                </Box>
                {/* popup */}
                {getLocation?.announcement_status == "active" && (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="announcement"
                        >
                        {mainTheme.layout == 1 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={handleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        {mainTheme.layout == 2 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'50%',
                                maxWidth:'30px',
                                minWidth:'30px',
                                padding:0,
                                margin:0,
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={handleClose}
                            color='error' 
                            variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                        ) : null}
                        {mainTheme.layout == 3 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={handleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        {mainTheme.layout == 4 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'50%',
                                maxWidth:'30px',
                                minWidth:'30px',
                                padding:0,
                                margin:0,
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={handleClose}
                            color='error' 
                            variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                        ) : null}
                        {mainTheme.layout == 5 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={handleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        <DialogContent 
                            sx={{
                                backgroundColor:getLocation.announcement_bgcolor == '' ? '' : getLocation.announcement_bgcolor,
                                backgroundImage:getLocation.announcement_bgimage == '' ? '' : {xs:`url(${getLocation.announcement_bgimage_medium})`,md:`url(${getLocation.announcement_bgimage_medium})`,md:`url(${getLocation.announcement_bgimage})`,lg:`url(${getLocation.announcement_bgimage})`},
                                backgroundSize: 'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',
                            }}>
                            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
                                <Typography
                                    sx={{ textAlign: 'center' , my:1}}
                                    variant="h4"
                                    dangerouslySetInnerHTML={{ __html: getLocation.announcement_title }} />
                                <Typography
                                    sx={{ textAlign: 'center' , my:1}}
                                    dangerouslySetInnerHTML={{ __html: getLocation.announcement_description }} />
                                {mainTheme?.layout == 5 && (
                                    <ButtonGroup
                                        disableElevation
                                        variant="contained"
                                        aria-label="Disabled button group"
                                    >
                                        <Button
                                            sx={{
                                                my:1.5,
                                                backgroundColor:`${themeData?.button_color}`,
                                                color:`${themeData?.button_font_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                fontWeight:600,
                                                '&:hover': {
                                                backgroundColor:`${themeData?.button_font_color}`,
                                                color:`${themeData?.button_color}`,
                                                border:`1px solid ${themeData?.button_color}`,
                                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                                }
                                            }}
                                            href={`${getLocation.button_url}`}
                                        >
                                            <Typography sx={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: getLocation?.button }} />
                                        </Button>
                                        <Button href={`${getLocation.button_url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                                    </ButtonGroup>
                                )}
                                {mainTheme.layout == 1 ? (
                                    <Button 
                                        href={`${getLocation.button_url}`}
                                        sx={{
                                            my:1,
                                            boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                            backgroundColor:`${getLocation?.button_color}`,
                                            borderRadius:'50px',
                                            fontWeight:600,
                                            '&:hover': {
                                            backgroundColor:`${getLocation?.button_color}`,
                                            borderRadius:'50px',
                                            }
                                        }} 
                                        >
                                        <Typography sx={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: getLocation?.button }} />
                                    </Button>
                                ) : null}
                                {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                                    <Button
                                        href={`${getLocation.button_url}`}
                                        sx={{
                                            my:1,
                                            boxShadow:{xs:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'},
                                            backgroundColor:`${getLocation?.button_color}`,
                                            borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                            fontWeight:600,
                                            '&:hover': {
                                                backgroundColor:`${getLocation?.button_color}`,
                                                borderRadius: mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                            }
                                        }}
                                        >
                                        {mainTheme?.layout == 4 ? <AdsClickIcon sx={{color:'#fff',mx:1}} /> : ''}
                                        <Typography sx={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: getLocation?.button }} />
                                    </Button>
                                ) : null}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                )}

                {/* res close modal */}
                {getresclose?.is_today == "3" && (
                    <Dialog
                        open={Resclosemodal}
                        onClose={resclosehandleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="res_close_modal"
                        >   
                        {mainTheme.layout == 1 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={resclosehandleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        {mainTheme.layout == 2 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'50%',
                                maxWidth:'30px',
                                minWidth:'30px',
                                padding:0,
                                margin:0,
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={resclosehandleClose}
                            color='error' 
                            variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                        ) : null}
                        {mainTheme.layout == 3 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={resclosehandleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        {mainTheme.layout == 4 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'50%',
                                maxWidth:'30px',
                                minWidth:'30px',
                                padding:0,
                                margin:0,
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={resclosehandleClose}
                            color='error' 
                            variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                        ) : null}
                        {mainTheme.layout == 5 ? (
                            <Button sx={{
                                position:'absolute',
                                zIndex:10,
                                right:'5px',
                                top:'5px',
                                borderRadius:'5px',
                                padding:0,
                                margin:0,
                                maxWidth:'30px',
                                minWidth:'30px',
                                width:'30px',
                                height:'30px',
                                boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                            }} 
                            onClick={resclosehandleClose}
                            color='error' 
                            variant='contained'>
                                <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                            </Button>
                        ) : null}
                        
                        <DialogContent>
                            {getresclose.layout == 1 && (
                                <>
                                    <img src={`${process.env.PUBLIC_URL + '/hanger.png'}`} style={{ borderRadius: '30px',position:'relative',top:'10px'}}/>
                                    <DialogContentText id="alert-dialog-description" sx={{backgroundColor:'#fb0814',textAlign:'center',width: '-webkit-fill-available',border:'1px solid #ddd',borderRadius: '10px'}}>
                                        {getresclose?.template_id > 0 && getresclose.title && ( 
                                            <>
                                            {getresclose?.template_id == 3 ? ( 
                                                <>                                       
                                                    <Typography variant="h2" className="close_modal_title"
                                                        sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >Dine-in Open</Typography>  
                                                    <Typography variant="h2" className="close_modal_des"
                                                        sx={{color:'#fff',textAlign: 'center'}}
                                                    >Order online unavailable</Typography>
                                                </>
                                            ):(
                                                <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', my:5}}
                                                >{getresclose.title}</Typography>
                                            )}
                                            </>
                                        )}    
                                        {getresclose?.template_id == 0 && ( 
                                            <>                   
                                                {getresclose?.title && (
                                                    <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >{getresclose.title}</Typography>
                                                )} 
                                                {getresclose?.reason && (
                                                    <Typography variant="h5" className="close_modal_des"
                                                    sx={{color:'#fff',textAlign: 'center'}}
                                                    >{getresclose.reason}</Typography>
                                                )} 
                                            </>
                                        )}
                                        <Typography
                                        sx={{ textAlign: 'center' ,color:"red", my:3,color:'#fff'}}
                                        variant="h6"
                                        >Sorry for any inconvenience</Typography>                     
                                    </DialogContentText>
                                </>
                            )}
                            {getresclose.layout == 2 && (
                                <>
                                    <img src={`${process.env.PUBLIC_URL + '/hanger_layout2.png'}`}/>
                                    <DialogContentText id="alert-dialog-description" sx={{backgroundColor:'blue',textAlign:'center',width: '-webkit-fill-available',border:'1px solid #ddd',borderRadius: '10px'}}>
                                        {getresclose?.template_id > 0 && getresclose.title && ( 
                                            <>
                                            {getresclose?.template_id == 3 ? ( 
                                                <>                                       
                                                    <Typography variant="h2" className="close_modal_title"
                                                        sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >Dine-in Open</Typography>  
                                                    <Typography variant="h2" className="close_modal_des"
                                                        sx={{color:'#fff',textAlign: 'center'}}
                                                    >Order online unavailable</Typography>
                                                </>
                                            ):(
                                                <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', my:5}}
                                                >{getresclose.title}</Typography>
                                            )}
                                            </>
                                        )}    
                                        {getresclose?.template_id == 0 && ( 
                                            <>                   
                                                {getresclose?.title && (
                                                    <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >{getresclose.title}</Typography>
                                                )} 
                                                {getresclose?.reason && (
                                                    <Typography variant="h5" className="close_modal_des"
                                                    sx={{color:'#fff',textAlign: 'center'}}
                                                    >{getresclose.reason}</Typography>
                                                )} 
                                            </>
                                        )}
                                        <Typography
                                        sx={{ textAlign: 'center' ,color:"red", my:3,color:'#fff'}}
                                        variant="h6"
                                        >Sorry for any inconvenience</Typography>                     
                                    </DialogContentText>
                                </>
                            )}
                            {getresclose.layout == 3 && (
                                <>
                                    <img src={`${process.env.PUBLIC_URL + '/layout3.png'}`}/>
                                    <DialogContentText id="alert-dialog-description" sx={{backgroundColor:'#0a3431',textAlign:'center',width: '-webkit-fill-available',border:'1px solid #ddd',borderRadius: '10px'}}>
                                        {getresclose?.template_id > 0 && getresclose.title && ( 
                                            <>
                                            {getresclose?.template_id == 3 ? ( 
                                                <>                                       
                                                    <Typography variant="h2" className="close_modal_title"
                                                        sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >Dine-in Open</Typography>  
                                                    <Typography variant="h2" className="close_modal_des"
                                                        sx={{color:'#fff',textAlign: 'center'}}
                                                    >Order online unavailable</Typography>
                                                </>
                                            ):(
                                                <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', my:5}}
                                                >{getresclose.title}</Typography>
                                            )}
                                            </>
                                        )}    
                                        {getresclose?.template_id == 0 && ( 
                                            <>                   
                                                {getresclose?.title && (
                                                    <Typography variant="h2" className="close_modal_title"
                                                    sx={{color:'#fff',textAlign: 'center', margin:'15px',padding:'10px',borderBottom:'2px solid #fff'}}
                                                    >{getresclose.title}</Typography>
                                                )} 
                                                {getresclose?.reason && (
                                                    <Typography variant="h5" className="close_modal_des"
                                                    sx={{color:'#fff',textAlign: 'center'}}
                                                    >{getresclose.reason}</Typography>
                                                )} 
                                            </>
                                        )}
                                        <Typography
                                        sx={{ textAlign: 'center' ,color:"red", my:3,color:'#fff'}}
                                        variant="h6"
                                        >Sorry for any inconvenience</Typography>                     
                                    </DialogContentText>
                                </>
                            )}
                        </DialogContent>
                    </Dialog>
                )}
                {/* res cookies */}  
                {showcookies && (              
                    <Alert className="res_cookies_modal">
                        <Typography variant="p" sx={{color:'#000',textAlign: 'center', my:1}}>
                            Our website uses cookies to ensure you get the best experience on our website.                       
                            <a href="https://www.eatstations.com/api/staticpage/cookie_policy" target="blank"> Learn more <br></br></a>
                        </Typography> 
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled button group"
                        >
                            <Button
                             onClick={handlecookiesClose}
                            sx={{
                                my:1.5,
                                backgroundColor:`${themeData?.button_color}`,
                                color:`${themeData?.button_font_color}`,
                                borderRadius: '4px !important',margin:'10px 5px',
                                fontWeight:600,
                                '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius: '4px',
                                }
                            }}
                            >Opt Out</Button>
                            <Button
                            onClick={handlecookiesGotit} 
                            sx={{
                                my:1.5,
                                backgroundColor:`${themeData?.button_color}`,
                                color:`${themeData?.button_font_color}`,
                                borderRadius: '4px !important',margin:'10px 5px',
                                fontWeight:600,
                                '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius: '4px',
                                }
                            }}
                            >Got it!</Button>
                        </ButtonGroup>
                    </Alert>
                )}
            </>

        );
    }else{
        return null;
    }
}

export default Home;
